.home {
  width: 100%;
  height: 100%;
  position: fixed;
}

.side-dir {
  height: 12rem;
  width: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.7rem;
  .scroll-side {
    height: 9rem;
    width: 1px;
    background: rgb(26, 26, 27);
    transform-origin: top;
    transform: scaleY(0);
  }
  .side-svg {
    height: 1.9rem;
    width: 1.9rem;
    transform: translate(0rem, -0.2rem);
    path {
      stroke-dasharray: 648px;
      stroke-dashoffset: 648px;
    }
  }
}

.gallery-container {
  position: fixed;
  right: 0;
  top: calc(50vh - 42.5rem);
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.home-gallery-media {
  height: 85rem;
  width: 77.6rem;
  position: relative;
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 50;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    opacity: 0;
  }
  @media (max-width: 768px) {
    width: 40vw;
  }
}

.home-txt-wrap {
  position: fixed;
  bottom: 39%;
  left: 0;
  text-align: center;
  width: 114.4rem;
  height: 20rem;
  opacity: 0;
}

.home-hero-text {
  position: absolute;
  top: 0;
  left: 0;
  height: 10.6rem;
  width: 100%;
  opacity: 0;
  transition: 0s ease-in-out;
  font-family: "Inter";
  font-weight: 400;

  .h-p-w {
    p {
      text-align: center;
    }
  }

  h3 {
    font-size: 3.5rem;
    letter-spacing: 0.105rem;
    margin-bottom: 5rem;
    display: inline-block;
    overflow: hidden;
    font-weight: 500;
    text-transform: uppercase;
    width: 100%;
    text-align: center;

    span {
      display: inline-block;
      // transform: translateY(100%);
    }

    .hero-name-h {
      display: inline-block;
      transform: rotate3d(50deg, 50deg, 0) translateY(150%) scale(0);
      transform-origin: 80% 50%;
    }
  }

  p {
    text-align: left;
    width: 100%;
    font-weight: 400;
    font-size: 1.5rem;
    text-transform: uppercase;
    overflow: hidden;
    span {
      display: inline-block;
      // transform: translateY(100%);
    }

    @media (max-width: 768px) {
      font-size: 5rem;
      margin-top: 5rem;
    }
    .hero-h {
      // display: inline-block;
      // transform: translateY(100%);
    }
  }
}

.space {
  width: 1.5rem;
}

.mask {
  position: fixed;
  width: 65vh;
  height: 24.2vh;
  background: #f7ecdd;
  top: 0;
  right: 0;
  opacity: 0;
  z-index: 40;
  display: none;

  @media (max-width: 768px) {
  }
  width: 50vw;
}

.credit {
  p {
    overflow: hidden;
  }
}

.about-name-h,
.about-date-h {
  display: inline-block;
}

.case-study-link {
  position: fixed;
  display: flex;
  top: 19.5vh;
  z-index: 120;
  font-size: 2rem;
  cursor: pointer;
  overflow: hidden;
  transform: translateX(0.8rem);
  visibility: hidden;
  p {
    overflow: hidden;
    display: inline-block;
    height: 3rem;
    line-height: 3rem;

    span {
      display: inline-block;
      opacity: 0;
      transform-origin: 80% 50%;
    }
  }
}

.h-hy {
  height: 1.75rem;
  width: 1px;
  background-color: black;
  position: absolute;
  left: 45%;
  top: 50%;
  transform: translate(60%, -50%) scaleY(0);
}

.br-t {
  position: fixed;
  top: -2.8rem;
  left: 0.8rem;
  transform: rotate(-90deg);
  width: 1rem;
  height: 3rem;
  font-size: 2rem;
  overflow: hidden;

  .br-t-c {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.br-b {
  position: fixed;
  bottom: -2.8rem;
  left: 0.7rem;
  transform: rotate(90deg);
  width: 1rem;
  height: 3rem;
  font-size: 2rem;
  overflow: hidden;

  .br-b-c {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.count {
  position: fixed;
  height: 8rem;
  width: 2rem;
  opacity: 0;
  right: 80rem;
  bottom: 50%;
  transform: translateY(50%) rotate(180deg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Poppins";
  font-size: 2rem;

  .h-curr,
  .h-total {
    transform: rotate(90deg);
    overflow: hidden;
    line-height: 2.2rem;
    height: 2rem;

    span {
      display: inline-block;
      transform: translateY(-100%);
    }
  }
}

.case-link-space {
  width: 0.3rem;
  display: inline-block;
}

.case-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: rgb(26, 26, 27);
  transform-origin: right;
  transform: scaleX(0);
}

.home-hero-text.is-active {
  opacity: 1;
}
