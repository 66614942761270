.header {
  position: fixed;
  top: 0;
  width: calc(50% + 6.29rem);
  padding-top: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  font-size: 2.2rem;
  transform: translateY(-100%);
  color: white;
  mix-blend-mode: difference;
}

.logo-container {
  position: relative;
  width: 6rem;
  height: 6rem;
  @media (max-width: 768px) {
    width: 9rem;
    height: 9rem;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    image-rendering: optimizeQuality;
  }
}

.margin-line {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  background: rgb(26, 26, 27);
  transform-origin: right;
  width: 100%;
}

.header-list {
  display: flex;
  width: 12.5rem;
  justify-content: space-between;
  li {
    mix-blend-mode: difference;
    a {
      mix-blend-mode: difference;
      font-size: 2rem;
      font-weight: 400;
    }
  }
}

.bullet {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 3rem;
  @media (max-width: 768px) {
    margin-top: 3rem;
  }
}

.header-nav {
  position: fixed;
  right: 5rem;
  // z-index: 2000;
}

.header-list {
  li {
    height: 1.75rem;
    line-height: 1.5rem;
    overflow: hidden;
    a {
      display: inline-block;
    }
  }
  .nav-index {
    display: inline-block;
    transform: translateY(105%);
  }
}

.header-name {
  visibility: hidden;
}

.header-name-link {
  width: 25rem;
  height: 8rem;
  position: relative;
  display: flex;
  font-size: 2rem;
  transform: translate(2.9rem, -5rem) rotate(5deg);
  span {
    display: inline-block;
    width: 2.5rem;
    height: 10rem;

    position: absolute;
    transform-origin: bottom;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.name-rot:nth-child(1) {
  transform: rotate(-65deg);
}

.name-rot:nth-child(2) {
  transform: rotate(-55deg);
}

.name-rot:nth-child(3) {
  transform: rotate(-45deg);
}

.name-rot:nth-child(4) {
  transform: rotate(-35deg);
}

.name-rot:nth-child(5) {
  transform: rotate(-25deg);
}

.name-rot:nth-child(6) {
  transform: rotate(-15deg);
}

.name-rot:nth-child(7) {
  transform: rotate(-5deg);
}

.name-rot:nth-child(8) {
  transform: rotate(5deg);
}

.name-rot:nth-child(9) {
  transform: rotate(15deg);
}

.name-rot:nth-child(10) {
  transform: rotate(25deg);
}

.name-rot:nth-child(11) {
  transform: rotate(35deg);
}

.name-rot:nth-child(12) {
  transform: rotate(45deg);
}

.name-rot:nth-child(13) {
  transform: rotate(55deg);
}

.name-rot:nth-child(14) {
  transform: rotate(65deg);
}

.balls-container {
  position: absolute;
  bottom: -4rem;
  left: 52%;
  transform: translateX(-50%);
}

.punc {
  transform: translateY(1rem);
}

.neg {
  transform: translateY(-1rem);
}
