.project {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: calc(27rem - 35px);
  padding-bottom: 5rem;

  .lt {
    margin-bottom: 5rem;
  }

  .video-ctn {
    width: 95rem;
    height: 81rem;
    background-color: rgb(15, 13, 15);
    position: relative;
    margin-left: 49rem;
    margin-top: 35px;
  }

  .v-mk {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(15, 13, 15);
    z-index: 5;
  }

  img {
    width: 95rem;
    height: 60rem;
    object-fit: cover;
  }

  video {
    visibility: visible;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .v-ct {
    object-fit: contain;
  }

  .video-pt {
    height: 94rem;
  }
}

.project-gl {
  position: relative;
  width: 95rem;
  height: 60rem;
  display: flex;
  margin-top: 35px;
  margin-left: 49rem;
  img {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.project.video {
  gap: 30vh;
  padding-bottom: 1rem;
}

.project_description {
  position: fixed;
  left: 5rem;
  top: 27rem;
  font-size: 1.86rem;
  font-family: "Poppins";

  p {
    overflow: hidden;
  }

  .ft {
    // font-family: "Inter";
    // font-size: 1.75rem;
    // font-weight: 700;
    font-size: 2rem;
    font-family: "Poppins";
  }
  .th {
    margin-left: 0.25rem;
  }
  span {
    display: inline-block;
  }

  .project-rev {
    transform: translateY(105%);
  }
}

.bd {
  font-weight: 900;
}

.project_description_text {
  margin: 5rem 0 3rem 0;
  line-height: 2.3rem;
}

.project_description_title p,
.date-link p {
  line-height: 2rem;
  font-size: 2rem;
}

.project-title {
  margin-bottom: 5rem;
  font-family: "Inter";
  font-size: 2rem;
  line-height: 2.2rem;
  font-weight: 500;
}

.project-link {
  overflow: hidden;
  a {
    margin-left: 0;
    display: inline-block;
    width: 10rem;
    font-family: "Inter";
    font-size: 2rem;
    font-weight: 500;
    margin-top: 4.6rem;
  }
}

.date-link {
  margin-top: 1rem;
}

#project {
  position: absolute;
  width: 2rem;
  height: 2rem;
  bottom: 0.7rem;
  left: 8.7rem;
  pointer-events: none;
}

.project-ar {
  position: absolute;
  width: 1rem;
  bottom: 0.1rem;
  left: 9.25rem;
  transform: translateX(-2rem);
  opacity: 0;
  pointer-events: none;
}

.next-p {
  position: absolute;
  right: 6rem;
  top: 27rem;

  .next-rf {
    width: 20rem;
    height: 20rem;
    // background-color: rgb(20, 20, 20);
    transform-origin: middle left;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      visibility: visible;
    }
  }

  .next-text {
    font-family: "Poppins";
    font-size: 2rem;
    top: -3rem;
    left: 47.5%;
    width: max-content;
    transform: translateX(-50%);
  }

  .next-title {
    font-family: "Inter";
    font-size: 2rem;
    bottom: -2.8rem;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
  }

  .next-text,
  .next-title {
    // text-align: center;
    opacity: 0;
    transition: opacity 0.15s ease;
    position: absolute;
  }

  .next-mk {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: rgb(56, 56, 56);
    z-index: 5;
    transition: opacity 0.15s ease;
    display: none;
  }

  .next-lk {
    position: fixed;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 6;
    pointer-events: none;
  }
}
