html.mobile {
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 100%;
  width: 100%;
  body {
    min-height: 100%;
    width: 100%;
    position: relative;
    overflow: visible;
    overscroll-behavior: none;
    padding: 0;
  }

  .h-title {
    font-size: 7rem;
    font-family: "Inter";
    font-weight: 400;
    position: fixed;
    top: 0;
    left: 10rem;
    top: 5rem;
    text-align: left;
    letter-spacing: -0.05rem;
  }

  .content {
  }

  .h-m-b {
    text-align: center;
    font-size: 12rem;
    line-height: 12rem;
    font-family: "Inter";
    font-weight: 500;
    margin-bottom: 5rem;
    text-transform: uppercase;
  }

  main {
    position: relative;
    overflow: auto;
  }
  .contact-links,
  .header,
  .preloader,
  #gl {
    display: none;
  }
  .h-m {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin-top: 30%;
    min-height: 100%;
  }
}

.avl {
  font-family: "Inter";
  font-weight: 400;
  font-size: 5rem;
  text-align: center;
  margin: 0 auto;

  a {
    display: block;
    text-align: left;
  }
}

.h-title {
  font-family: "Inter";
  font-size: 25rem;
  line-height: 15rem;
  width: 50%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0;
  font-weight: 500;
  letter-spacing: -0.8rem;
}

.h-sm {
  margin: 0 auto;

  ul {
    display: flex;
    gap: 5rem;
    font-family: "Inter";
    font-size: 6rem;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  user-select: none;
  font-smooth: antialiased;
  font-size: calc(100vw / 1920 * 10);
  touch-action: none;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: white;
}

body {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  // background-color: rgb(27, 27, 26);
  overscroll-behavior: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  padding: 5rem;
  color: #1b1b1a;
}

.bio,
.nw {
  display: none;
}

#gl {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}

ul {
  list-style: none;
}

main {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
}

a {
  text-decoration: none;
  color: inherit;
}

.n-f {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p,
  a {
    overflow: hidden;
    display: block;

    span {
      display: inline-block;
      transform: translateY(100%);
    }
  }
}

.nf-f-tx {
  font-family: "Inter";
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.contact-links {
  position: fixed;
  bottom: 2rem;
  font-family: "Inter";
  font-size: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  mix-blend-mode: difference;
  color: white;
  z-index: 2;
  ul {
    display: flex;
    gap: 1rem;
  }
  li {
    overflow: hidden;
    height: 1.8rem;

    a {
      display: inline-block;
      transform: translateY(110%);
      @extend %link;
      font-weight: 400;
      line-height: 1;
    }
  }
}

.black {
  position: fixed;
  visibility: hidden;
  font-family: "Inter";
  font-weight: 500;
}

.date-header {
  position: fixed;
  left: 0;
  top: 8rem;
  width: 5rem;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 3rem;
  @media (max-width: 768px) {
    width: 10rem;
    top: 15rem;
    font-size: 4.5rem;
  }
}
.header {
  .header-logo {
    // text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: 500;
    font-family: "Inter";
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    transform: translateY(0);
  }
}

.header-nav-item {
  a {
    font-size: 2rem;
    text-transform: none;
    font-family: "Poppins";
  }
}
.header-circles {
  height: 5rem;
  position: relative;
}

.header-circles.nameh {
  transform: rotate(-5deg) translateY(1.3rem);
}

.circ1,
.circ2,
.circ3 {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 40%;
  position: absolute;
  border: 1px solid rgb(27, 27, 26);
  z-index: 2;
}

.circ1 {
  top: 0.9rem;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
}

.circ3 {
  bottom: 0.9rem;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
}

.circ2 {
  background: #eeeeee;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.date-line {
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  background: rgb(26, 26, 27);
  transform-origin: top;
  height: 100%;
}

.p-wp {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  color: rgb(247, 236, 221);
  font-size: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";

  .preloader-bg {
    width: 100%;
    height: 100%;
    background: rgb(26, 26, 27);
    position: absolute;
    top: 0;
    left: 0;
  }

  .preloader-name-f {
    display: flex;
    margin-right: 5rem;
    overflow: hidden;
    .preloader-name-f-t {
      display: inline-block;
      transform: rotateX(180deg) rotateY(180deg) translateY(100%);
      animation: loaderAnim 0.875s 1s cubic-bezier(0.15, 0.57, 0.09, 0.97)
        forwards;
    }
    .preloader-name-f-t:nth-child(2) {
      animation-delay: 1.08s;
    }
    .preloader-name-f-t:nth-child(3) {
      animation-delay: 1.16s;
    }
    .preloader-name-f-t:nth-child(4) {
      animation-delay: 1.8s;
    }
  }

  .wrapper {
    display: inline-block;
  }

  .preloader-name-l {
    display: flex;
    overflow: hidden;
    .preloader-name-l-t {
      display: inline-block;
      transform: rotateX(180deg) rotateY(180deg) translateY(100%);
      transform-origin: 30% 50%;
    }
    .preloader-name-l-t:nth-child(14) {
      animation: loaderAnim 0.875s cubic-bezier(0.15, 0.57, 0.09, 0.97) forwards;
      animation-delay: 1.8s;
    }

    .preloader-name-l-t:nth-child(13) {
      animation: loaderAnim 0.875s cubic-bezier(0.15, 0.57, 0.09, 0.97) forwards;
      animation-delay: 0.84s;
    }

    .preloader-name-l-t:nth-child(12) {
      animation: loaderAnim 0.875s cubic-bezier(0.15, 0.57, 0.09, 0.97) forwards;
      animation-delay: 0.88s;
    }

    .preloader-name-l-t:nth-child(11) {
      animation: loaderAnim 0.875s cubic-bezier(0.15, 0.57, 0.09, 0.97) forwards;
      animation-delay: 0.92s;
    }

    .preloader-name-l-t:nth-child(10) {
      animation: loaderAnim 0.875s cubic-bezier(0.15, 0.57, 0.09, 0.97) forwards;
      animation-delay: 0.96s;
    }

    .preloader-name-l-t:nth-child(9) {
      animation: loaderAnim 0.875s cubic-bezier(0.15, 0.57, 0.09, 0.97) forwards;
      animation-delay: 1s;
    }

    .preloader-name-l-t:nth-child(8) {
      animation: loaderAnim 0.875s cubic-bezier(0.15, 0.57, 0.09, 0.97) forwards;
      animation-delay: 1.04s;
    }
    .preloader-name-l-t:nth-child(7) {
      animation: loaderAnim 0.875s cubic-bezier(0.15, 0.57, 0.09, 0.97) forwards;
      animation-delay: 1.08s;
    }
    .preloader-name-l-t:nth-child(6) {
      animation: loaderAnim 0.875s cubic-bezier(0.15, 0.57, 0.09, 0.97) forwards;
      animation-delay: 1.8s;
    }
    .preloader-name-l-t:nth-child(5) {
      animation: loaderAnim 0.875s cubic-bezier(0.15, 0.57, 0.09, 0.97) forwards;
      animation-delay: 1.16s;
    }
    .preloader-name-l-t:nth-child(4) {
      animation: loaderAnim 0.875s cubic-bezier(0.15, 0.57, 0.09, 0.97) forwards;
      animation-delay: 1.2s;
    }
    .preloader-name-l-t:nth-child(3) {
      animation: loaderAnim 0.875s cubic-bezier(0.15, 0.57, 0.09, 0.97) forwards;
      animation-delay: 1.24s;
    }
    .preloader-name-l-t:nth-child(2) {
      animation: loaderAnim 0.875s cubic-bezier(0.15, 0.57, 0.09, 0.97) forwards;
      animation-delay: 1.28s;
    }
    .preloader-name-l-t:nth-child(1) {
      animation: loaderAnim 0.875s cubic-bezier(0.15, 0.57, 0.09, 0.97) forwards;
      animation-delay: 1.32s;
    }
  }

  .preloader-space {
    display: inline-block;
    width: 5rem;
  }

  .preloader-name-b {
    display: inline-block;
  }

  .preloader-svg {
    width: 7rem;
    height: 7rem;
    transform: translateY(-15rem);
    visibility: hidden;
    path {
      stroke-dasharray: 3503px;
      stroke-dashoffset: 3503px;
      animation: loaderSvg 2.7s 1s cubic-bezier(1, 0.07, 0, 0.94) forwards;
    }
  }
}

.preloader-folio {
  position: fixed;
}

.preloader-folio.bottom {
  bottom: 2rem;
  left: 2rem;
}

.preloader-folio.top {
  top: 2rem;
  right: 2rem;
}

.preloader-folio-text {
  display: flex;
  font-size: 3rem;
  overflow: hidden;
}

.folio-t {
  transform: translateX(-100%);
  opacity: 0;
  animation: folioAnim 1s 1s ease-out forwards;
}

@keyframes folioAnim {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes loaderSvg {
  from {
    stroke-dashoffset: 3503px;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes loaderAnim {
  from {
    transform: rotateX(180deg) rotateY(180deg) translateY(100%);
    opacity: 0;
  }
  to {
    transform: rotateX(0) rotateY(0) translateY(0);
    opacity: 1;
  }
}

// html.mobile {
//   .header {
//     display: none;
//   }
//   .preloader {
//     display: none;
//     align-items: flex-start;
//     padding-top: 10vh;
//     color: #1b1b1a;
//     font-weight: 900;
//   }

//   .preloader-folio {
//     display: none;
//   }

//   .contact-links {
//     display: none;
//   }

//   .preloader-bg {
//     background: rgb(244, 236, 236);
//   }

//   .preloader-name-f,
//   .preloader-name-l {
//     display: none;
//   }

//   .bio {
//     display: block;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     font-size: 8.5rem;
//     font-family: "Poppins";
//     line-height: 9.8rem;
//     width: 80%;
//     margin: 0 auto;
//     text-align: left;
//     display: none;
//     animation: bio 0.5s 8s forwards;

//     p {
//       overflow: hidden;

//       span {
//         display: inline-block;
//         // transform: translateY(100%);
//       }
//     }
//   }

//   .nw {
//     display: block;
//     color: black;
//     position: absolute;
//     bottom: 15vh;
//     font-size: 5rem;
//     font-family: "Inter";
//     font-weight: 500;
//     line-height: 5.5rem;
//     display: flex;
//     justify-content: space-between;
//     padding: 0 20rem;
//     width: 100%;
//     text-transform: uppercase;
//     // display: none;

//     a {
//       overflow: hidden;

//       span {
//         display: inline-block;
//         // transform: translateY(100%);
//       }
//     }
//   }
// }

// @keyframes bio {
//   from {
//     display: "none";
//   }
//   to {
//     display: "block";
//   }
// }
