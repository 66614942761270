.about {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  visibility: hidden;
}

.about-hero {
  margin-top: 5rem;
  width: 100%;
  height: 100vh;
  position: relative;
}

.about-name-intro {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  font-family: "Inter";
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;

  .about-sc {
    position: absolute;
    left: 50%;
    top: -7rem;
    transform: translate(-50%, 0);
    font-family: "Inter";
    font-weight: 400;
    font-size: 1.5rem;
    overflow: hidden;
    height: 2.15rem;
    span {
      display: inline-block;
      transform: translateY(100%);
    }
  }
  p {
    display: flex;
    justify-content: center;
    overflow: hidden;

    span {
      transform: translateY(100%);
      font-size: 6.4rem;
      line-height: 5.8rem;
      letter-spacing: 0.32rem;
    }
  }
}

.pic-credits {
  display: flex;
  position: relative;
}

.about-bio {
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem;
  line-height: 2.2rem;
  font-family: "Poppins";
  p {
    mix-blend-mode: difference;
  }
}

.bio-p {
  overflow: hidden;
}

.bio-t {
  display: inline-block;
  transform: translateY(100%);
}

.me-container {
  height: 70rem;
  width: 49rem;
  position: relative;
  margin-left: 70rem;
  img {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.about-f-name,
.about-l-name {
  opacity: 0;
}

.about-l-space {
  width: 5rem;
}

.item-credits {
  transform: translateY(-10rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-family: "Inter";
  width: 71rem;
  margin: 0 auto;
  padding: 5rem 0;
  display: none;
}

.credit {
  display: flex;
  width: 70rem;
  height: 5rem;
  justify-content: space-between;
  align-items: center;
  padding: 5.5rem 0;
  position: relative;
  pointer-events: all;
  z-index: 10;
  cursor: pointer;
}

.about-date {
  font-family: "Poppins";
  line-height: 2.8rem;
  font-size: 2rem;
  transform: translateY(0.5rem);
}

.about-date,
.about-name {
  pointer-events: none;
}

.hero-media.odd {
  height: 13rem;
  width: 20rem;
  position: absolute;
  right: -23rem;
  @media (max-width: 768px) {
    display: none;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    visibility: hidden;
    pointer-events: none;
  }
}

.hero-media.even {
  height: 13rem;
  width: 20rem;
  position: absolute;
  left: -23rem;
  @media (max-width: 768px) {
    display: none;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    visibility: hidden;
    pointer-events: none;
  }
}

.about-underline {
  position: absolute;
  bottom: 0rem;
  width: 100%;
  height: 1px;
  left: 0;
  transform-origin: left;
  background: black;
  pointer-events: none;
}

.about-circle-border {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: black;
  position: relative;
  pointer-events: none;
}

.about-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: #f7ecdd;
  pointer-events: none;
}

.about-space {
  width: 3rem;
}

.about-in-progress {
  position: absolute;
  top: 50%;
  right: -13rem;
  font-size: 3rem;
  width: 10rem;
  color: rgb(247, 6, 6);
  text-align: center;
  border-top: 1px solid rgb(247, 6, 6);
  border-bottom: 1px solid rgb(247, 6, 6);
  overflow: hidden;
  transform: scaleX(0) translateY(-50%);
  transform-origin: left;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    display: flex;
    overflow: hidden;
    span {
      display: inline-block;
    }
  }
}

.about-in-progress.pe {
  left: -13rem;
}
